import React from 'react';
import type { AppProps } from 'next/app';
import { ErrorBoundary } from '@sentry/nextjs';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools'; // use on dev mode
import { ThemeProvider } from 'styled-components';

import { theme } from 'styles/global';
import GlobalStyle from 'styles/globalStyle';
import Layout from '@components/layout';
import Custom500 from './500';

export const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      staleTime: 3000, // 1분 60000
      refetchOnWindowFocus: false,
      keepPreviousData: true,
      retry: 3,
      refetchOnReconnect: true,
    },
  },
});

const App = ({ Component, pageProps }: AppProps) => {
  return (
    <QueryClientProvider client={queryClient}>
      <ReactQueryDevtools initialIsOpen={false} />

      <ThemeProvider theme={theme}>
        <ErrorBoundary
          beforeCapture={(scope) => {
            scope.setLevel('fatal');
          }}
          fallback={() => <Custom500 />}
        >
          <Layout>
            <GlobalStyle />
            <Component {...pageProps} />
          </Layout>
        </ErrorBoundary>
      </ThemeProvider>
    </QueryClientProvider>
  );
};

export default App;
