import * as Sentry from '@sentry/nextjs';

const SENTRY_DSN = process.env.SENTRY_DSN || process.env.NEXT_PUBLIC_SENTRY_DSN;

Sentry.init({
  dsn:
    SENTRY_DSN ||
    'https://7b54be855bd540c79a01bae7f618fc84@sentry.getcha.io/11',
  tracesSampleRate: 1.0,
});
