import { createGlobalStyle } from 'styled-components';

export const GlobalStyle = createGlobalStyle`
body{
  background-color: #f4f4f4;

}
  html,
  main,body, p,div,article,head,span, button, table, td,th, article, a {
  font-family: SpoqaHanSans-Regular;
    margin: 0;
    padding: 0;
    color:#242424;
  }
  h1,h2,h3,h4,h5,h6{
    font-family: SpoqaHanSans-Bold;
    margin: 0;
    padding: 0;
    color:#242424;
  }

  form{
    padding:0;
    margin:0;
  }
  
  div{
    // invisible scroll bar 
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none;
  

  &::-webkit-scrollbar {
    display: none; /* Chrome, Safari, Opera*/
  }
  }

  button {
    background: transparent;
    cursor: pointer;
    border:none;
    box-shadow: none;
  }
  a {
    color: inherit;
    text-decoration: none;
  }
  * {
    box-sizing: border-box;
  }

  @font-face {
    font-family: 'SpoqaHanSans-Regular';
    font-display: fallback;
    src: url('https://cloud.getcha.io/fonts/SpoqaHanSansNeo-Regular.woff2') format('woff2');
  }
  @font-face {
    font-family: 'SpoqaHanSans-Medium';
    font-display: fallback;
    src: url('https://cloud.getcha.io/fonts/SpoqaHanSansNeo-Medium.woff2') format('woff2');
  }
  @font-face {
    font-family: 'SpoqaHanSans-Bold';
    font-display: fallback;
    src: url('https://cloud.getcha.io/fonts/SpoqaHanSansNeo-Bold.woff2') format('woff2');
  }
  @font-face {
    font-family: 'SpoqaHanSans-Light';
    font-display: fallback;
    src: url('https://cloud.getcha.io/fonts/SpoqaHanSansNeo-Light.woff2') format('woff2');
  }
  @font-face {
    font-family: 'DSEG7ClassicMini-Bold';
    font-display: fallback;
    src: url('https://cloud.getcha.io/fonts/DSEG7ClassicMini-Bold.woff2') format('woff2');
  }
  @font-face {
    font-family: 'NotoSans-Regular';
    font-display: fallback;
    font-size:400;
    src: url('https://cloud.getcha.io/fonts/NotoSansKR-Regular.woff2') format('woff2');
  }
  @font-face {
    font-family: 'NotoSans-Medium';
    font-display: fallback;
    font-size:500;
    src: url('https://cloud.getcha.io/fonts/NotoSansKR-Medium.woff2') format('woff2');
  }
  @font-face {
    font-family: 'NotoSans-Bold';
    font-display: fallback;
    font-size:700;
    src: url('https://cloud.getcha.io/fonts/NotoSansKR-Bold.woff2') format('woff2');
  }



`;

export default GlobalStyle;
