import React from 'react';
import styled from 'styled-components';
import { useRouter } from 'next/router';
import Image from 'next/image';
import IMG404 from '@public/images/error/error_404.png';
import IMG500 from '@public/images/error/error_500.png';

interface ErrorPageProps {
  statusCode: number;
}

const ErrorPage = ({ statusCode = 404 }: ErrorPageProps) => {
  const nextRouter = useRouter();

  const onClickHome = () => {
    nextRouter.push('/');
  };

  const onClickBack = () => {
    nextRouter.back();
  };

  return (
    <Template>
      <Content>
        {statusCode === 404 && (
          <Image src={IMG404} alt="error" width={300} height={230} />
        )}
        {statusCode === 500 && (
          <Image src={IMG500} alt="error" width={300} height={230} />
        )}
        <ErrorTitle>
          {statusCode === 404 && <>페이지를 찾을 수 없어요.</>}
          {statusCode === 500 && <>시스템에 문제가 발생했어요.</>}
        </ErrorTitle>
        <ErrorExplain>
          {statusCode === 404 && (
            <>
              해당 페이지는 사라졌거나 변경되었습니다.
              <br />
              다시 한 번 확인해 주세요.
            </>
          )}

          {statusCode === 500 && (
            <>
              문제를 해결하기 위해 열심히 노력하고 있습니다.
              <br />
              잠시 후 다시 이용해 주세요.
            </>
          )}
        </ErrorExplain>
      </Content>
      <Footer>
        <ContactUs>오류가 계속되면 고객센터로 문의해주세요.</ContactUs>
        <NavBtnBlock>
          <BackButton onClick={onClickBack}>이전 페이지</BackButton>
          <HomeButton onClick={onClickHome}>홈으로 가기</HomeButton>
        </NavBtnBlock>
      </Footer>
    </Template>
  );
};

export default ErrorPage;

const Template = styled.div`
  @font-face {
    font-family: 'SpoqaHanSans-Medium';
    font-display: swap;
    src: url('https://cloud.getcha.io/fonts/SpoqaHanSansNeo-Medium.woff2')
      format('woff2');
  }

  display: flex;
  flex-direction: column;
  height: 100vh;

  p,
  div,
  span,
  button {
    font-family: SpoqaHanSans-Medium;
  }
`;

const Content = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  flex-direction: column;
  flex: 1;
`;

// const ErrorImage = styled.Image`
//   width: 300px;
//   height: 230px;
//   margin-bottom: 10px;
// `;

const ErrorTitle = styled.p`
  font-size: 18px;
  line-height: 24px;
  letter-spacing: -0.5px;
  text-align: center;
  margin-bottom: 10px;
`;

const ErrorExplain = styled.p`
  font-size: 14px;
  line-height: 24px;
  letter-spacing: -0.3px;
  text-align: center;
  color: ${({ theme }) => theme.base.grey500};
`;

const NavBtnBlock = styled.div`
  display: flex;
  margin-top: 18px;
`;

const Button = styled.button`
  border-radius: 4px;
  cursor: pointer;
  padding: 18px 40px;
  font-weight: bold;
  font-size: 16px;
  line-height: 20px;
  letter-spacing: -0.5px;
`;

const BackButton = styled(Button)`
  background-color: ${(props) => props.theme.base.grey200};
  color: ${(props) => props.theme.base.black};
  margin-right: 8px;
`;

const HomeButton = styled(Button)`
  background-color: ${(props) => props.theme.primary};
  color: ${(props) => props.theme.base.white};
  margin-right: 0;
`;

const ContactUs = styled.span`
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 20px;
  text-align: center;
  letter-spacing: -0.2px;
  color: #959595;
`;

const Footer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  flex: 0 1 auto;
  margin-bottom: 42px;
`;
