import { DefaultTheme } from 'styled-components';

export const MAX_WIDTH = 840;
/**
 * ThemeContext로 접근 가능한 테마입니다.
 * 현재는 컬러값만 적용하지만, 추후 자주 사용되는 테마가 정의될 예정입니다.
 */
export const theme: DefaultTheme = {
  primary: '#f34a4a',
  secondary: '#181818',
  base: {
    white: '#fff',
    grey050: '#FAFAFA',
    grey100: '#f7f7f7',
    grey200: '#efefef',
    grey250: '#eaeaea',
    grey300: '#e0e0e0',
    grey400: '#aeaeae',
    grey500: '#959595',
    grey600: '#7c7c7c',
    grey700: '#646464',
    grey800: '#4a4a4a',
    grey900: '#232323',
    lineGrey: '#dddddd',
    black2: '#232323',
    black1: '#121212',
  },
  extendedRed: {
    red100: '#fef5f5',
    red200: '#fde0e0',
    red300: '#fde0e0',
    red400: '#ef3333',
    red500: '#ab1212',
    red600: '#670000',
  },
  extendedBlue: {
    blue: '#0067DF',
    blue100: '#edf7ff',
    blue200: '#bbe3ff',
    blue300: '#79c7ff',
    blue400: '#0e99fc',
    blue500: '#0064aa',
    blue600: '#003459',
  },
  extendedGreen: {
    green100: '#efffee',
    green200: '#c7f9c4',
    green300: '#80ea7a',
    green400: '#38c52f',
    green500: '#15860d',
    green600: '#094605',
  },
  extendedOrange: {
    orange100: '#fff5eb',
    orange200: '#ffe1c6',
    orange300: '#ffb168',
    orange400: '#ff7a00',
    orange500: '#a04d00',
    orange600: '#582a00',
  },
};

/**
 * 기본 폰트 스타일 지정
 */

export const BasicFontStyle = `
line-height:100%;
letter-spacing: -0.02em;
`;

export const BoldStyle = `
font-family:SpoqaHanSans-Bold;
font-weight:700;
`;

export const MediumStyle = `
font-family:SpoqaHanSans-Medium;
font-weight:500;
`;

export const RegularStyle = `
font-family:SpoqaHanSans-Regular;
font-weight:400;
`;

export const B32 = `
  ${BasicFontStyle}
  ${BoldStyle}
  font-size:32px;
`;

export const B18 = `
${BasicFontStyle}
${BoldStyle}
  font-size:18px;
`;

export const B16 = `
${BasicFontStyle}
${BoldStyle}
  font-size:16px;
`;

export const B14 = `
${BasicFontStyle}
${BoldStyle}
  font-size:14px;
`;

export const B11 = `
${BasicFontStyle}
${BoldStyle}
  font-size:11px;
`;

export const M16 = `
${BasicFontStyle}
${MediumStyle}
  font-size:16px;
  line-height: 120%;
`;

export const M11 = `
${BasicFontStyle}
${MediumStyle}
  font-size:11px;
`;

export const M12 = `
${BasicFontStyle}
${MediumStyle}
  font-size:12px;
`;

export const R14 = `
${BasicFontStyle}
${RegularStyle}
  font-size:14px;
`;

export const R12 = `
${BasicFontStyle}
${RegularStyle}
  font-size:12px;
`;

export const R11 = `
${BasicFontStyle}
${RegularStyle}
  font-size:11px;
  line-height: 120%;
`;

export const NotoBold = `
font-family:NotoSans-Bold;
`;
export const NotoMedium = `
font-family:NotoSans-Medium;
`;
export const NotoRegular = `
font-family:NotoSans-Regular;
`;
