import { MAX_WIDTH } from '@styles/global';
import React from 'react';
import styled from 'styled-components';

interface LayoutProps {
  children: React.ReactNode;
}

const Layout = ({ children }: LayoutProps) => {
  return (
    <Container>
      <MainLayout>
        <Content>{children}</Content>
      </MainLayout>
    </Container>
  );
};

export default Layout;

const Container = styled.div`
  height: 100%;
  overflow-y: scroll;
  background-color: #f4f4f4;
`;

const MainLayout = styled.main`
  margin: 0 auto;
  height: 100%;
  background-color: ${(props) => props.theme.base.white};
`;

const Content = styled.div``;
